import React from 'react';
import styled, { css } from 'styled-components';
import ABGraph_bg from '../../assets/images/ABGraph_bg.jpg';
import ABGraph_bg_mobile from '../../assets/images/ABGraph_bg_mobile.jpg';
import ScrollLink from '../../components/atoms/ScrollLink';
import AntibodyDatabaseDiagram from '../../assets/images/ab_diagram.svg';
import {
  TextLarge,
  TextMedium,
  Title,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const IntroSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);
  const tabletLayout = useMaxWidthBreakpoint(1024);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <IntroSectionWrapper>
          <TextsBlock>
            <Upheader $color="delicateAccented">Products</Upheader>
            <SectionTitle>Antibody Database</SectionTitle>
            <SectionText>
              <SectionPunchline>
                Benefit from decades of research packed into Antibody Database.
              </SectionPunchline>
              A curated and comprehensive database that opens the doors to data
              accumulated through decades of research into therapeutic
              antibodies. Accelerate your research with easy access to antibody
              data that is no longer fragmented or non-standardized. Reap
              benefits from antibody-specific searches and generate novel
              conclusions about the biology of antibodies thanks to our data
              integration.
            </SectionText>
            {!tabletLayout && (
              <ScrollLink $color="delicate" to="#product-content">
                <ScrollIcon />
                Learn more
              </ScrollLink>
            )}
          </TextsBlock>
          <ImageDecoration
            height={612}
            src={AntibodyDatabaseDiagram}
            width={430}
          />
        </IntroSectionWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    mobileLayout
      ? css`
          background-image: url('${ABGraph_bg_mobile}');
        `
      : css`
          background-image: url('${ABGraph_bg}');
          height: 1000px;
        `}
  background-size: cover;
  background-blend-mode: overlay;
  background-position: top center;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 35%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost680pxWide(css`
    :before {
      width: 50%;
    }
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 455px;
  margin: 7px 0 25px;
`;

const SectionPunchline = styled(TextLarge).attrs({
  as: 'span',
  $color: 'primaryAccented',
})`
  display: block;
  max-width: 455px;
  margin: 7px 0 25px;
`;

const SectionText = styled(TextMedium).attrs({ $color: 'delicate' })`
  max-width: 424px;
  margin: 0 0 32px;
`;

const TextsBlock = styled.div`
  padding: 50px 0 20px 0;
`;

const ImageDecoration = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  padding-top: 200px;

  ${forScreenAtMost680pxWide(css`
    padding: 0;
    margin-bottom: 100px;
  `)}
`;

const IntroSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 50px;

  ${forScreenAtMost680pxWide(css`
    grid-template-columns: 1fr;
  `)}
`;

export default IntroSection;
