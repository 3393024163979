import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import DataSourcesSection from '../pages-components/antibody-database/DataSourcesSection';
import IntroSection from '../pages-components/antibody-database/IntroSection';
import NextProductSection from '../pages-components/shared/NextProductSection';
import routes from '../routes';

const ProductsPage = (): JSX.Element => {
  return (
    <Layout
      description="Accelerate your research with a curated and comprehensive knowledge graph that combines decades of research into antibody engineering."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Antibody Database"
    >
      <IntroSection />
      <DataSourcesSection />
      <NextProductSection
        href={routes.antibodyAnalytics}
        title="Antibody Analytics"
      />
    </Layout>
  );
};

export default ProductsPage;
