import React from 'react';
import styled, { css } from 'styled-components';
import NgsIcon from '../../assets/images/NgsIcon.inline.svg';
import orbits from '../../assets/images/orbits.svg';
import PatentDocsIcon from '../../assets/images/PatentDocsIcon.inline.svg';
import StructuresIcon from '../../assets/images/StructuresIcon.inline.svg';
import TherapeuticsIcon from '../../assets/images/TherapeuticsIcon.inline.svg';
import LiteratureIcon from '../../assets/images/literatureIcon.inline.svg';
import GenbankIcon from '../../assets/images/genbank.inline.svg';
import {
  Header,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import {
  forScreenAtMost1024pxWide,
  forScreenAtMost1200pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';

const dataSources = [
  {
    count: '01',
    icon: <PatentDocsIcon />,
    heading: 'Patents',
    content:
      'We collect antibodies associated with patent documents from major sources (Krawczyk et al. 2021). We identify the antibody sequences based on sequence features, patent document text, and classification to assess whether it contains antibodies or not. Each antibody sequence from a patent is furthermore associated with the text metadata of the document from which it originated to facilitate text-based searches for sequences associated with specific biological entities (e.g., particular targets). This database covers <strong> ~3,500,000 antibody sequences from USPTO, WIPO, DDBJ, and EBI (~280,000 unique sequences)</strong>.',
  },
  {
    count: '02',
    icon: <TherapeuticsIcon />,
    heading: 'Therapeutics',
    content: `We recently passed the mark of <strong> 100 antibodies approved </strong> by the FDA, with hundreds more undergoing clinical trials. We collect the sequences of such antibodies with assigned International Nonproprietary Names (INNs) and associate them with rich metadata such as target information. We currently hold data on more than <strong> 826 therapeutic antibodies </strong>.`,
  },
  {
    count: '03',
    icon: <StructuresIcon />,
    heading: 'Structures',
    content: `The Protein Data Bank (PDB) is the primary public source for three-dimensional conformation data on biomolecules. Antibody sequences from the PDB are identified by antibody sequence features and text mining of metadata fields associated with particular chains and the entire PDB documents. <strong>Currently, we identify more than 6,500 structural depositions containing antibodies</strong>.`,
  },
  {
    count: '04',
    icon: <LiteratureIcon />,
    heading: 'Literature',
    content: `Biological sequences often aren’t deposited in standardized repositories such as GenBank. Instead, they may be listed directly in scientific publications and their supplementary material. Currently, there’s no reliable automated method available to identify such sequences. That’s why this category encompasses antibody sequences we add to our database based on the manual curation of scientific publications. <strong> We currently have more than 5000 of those </strong>. Antibody sequences here are linked to the metadata of publications that they originate from and targets to facilitate text-based retrievals.`,
  },
  {
    count: '05',
    icon: <NgsIcon />,
    heading: 'NGS',
    content: `Next-Generation Sequencing now allows us to query the great sequence variability covered by antibody sequences. We identify bioprojects that specifically include NGS of antibodies. Querying and analyzing such datasets provides richer information on the sequence variability of antibodies than studying limited germline sequences available. Our version of the NGS database covers <strong> more than 200 bioprojects with a combined 25 billion raw reads</strong>.`,
  },
  {
    count: '06',
    icon: <GenbankIcon />,
    heading: 'GenBank',
    content: `NCBI GenBank is one of the primary structured sources for depositing biological sequences associated with scientific outputs. Antibodies from this database are identified by antibody/nanobody-like sequence features combined with text mining of the metadata of deposition. Each GenBank antibody sequence is associated with its source deposition document, which includes the source organism, deposition description, and source publication, which facilitates text-based retrieval of sequences associated with certain biological entities (e.g., particular targets). This database covers <strong> ~175,000 unique variable region sequences from ~200,000 accessions</strong>.`,
  },
];

const DataSourcesSection = (): JSX.Element => {
  return (
    <Wrapper id="product-content">
      <MainContentColumnWrapper>
        <Upheader $color="delicateAccented" $textAlign="center">
          Data sources
        </Upheader>
        {dataSources.map((dataSource) => (
          <DataSourceWrapper key={dataSource.count}>
            <Border />
            <DataSourceCount>{dataSource.count}.</DataSourceCount>
            <DataSourceIconWrapper>{dataSource.icon}</DataSourceIconWrapper>
            <DataSourceContentWrapper>
              <StyledHeader>{dataSource.heading}</StyledHeader>
              <TwoColumnText
                dangerouslySetInnerHTML={{ __html: dataSource.content }}
              />
            </DataSourceContentWrapper>
          </DataSourceWrapper>
        ))}
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  padding: 104px 0 0 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
`;

const Border = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-image: ${({ theme }) => theme.gradients.dotsPattern};
  background-position: left;
  background-size: 1px 5px;
  background-repeat: repeat-y;
`;

const DataSourceWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 440px;
  padding-bottom: 40px;

  :before {
    position: absolute;
    content: '';
    left: 5px;
    top: 1px;
    height: calc(100% + 10px);
    width: 20px;
    background-image: ${({ theme }) => theme.gradients.linesPattern};
    background-size: 20px 20px;
  }
  :after {
    position: absolute;
    content: '';
    left: 5px;
    top: 11px;
    height: 100%;
    width: 10px;
    background-image: ${({ theme }) => theme.gradients.linesPattern};
    background-size: 20px 20px;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &:nth-of-type(1) {
    ${Border} {
      top: -139px;
      height: calc(100% + 139px);
    }

    :before {
      top: -139px;
      height: calc(100% + 140px);
    }
    :after {
      top: -130px;
      height: calc(100% + 140px);
    }
  }

  &:last-of-type {
    ${Border} {
      height: 65%;
    }

    :before,
    :after {
      height: 60%;
    }
  }

  ${forScreenAtMost680pxWide(css`
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 30px;
    min-height: unset;
    padding-top: 116px;

    &:nth-of-type(1) {
      padding-top: 115px;
    }

    ${Border} {
      left: -25px;
    }

    :before,
    :after {
      left: -20px;
    }
  `)}
`;

const DataSourceCount = styled.span`
  display: block;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: ${({ theme }) => theme.fontSizes.abGraph.counter};
  color: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  margin-left: 75px;
  margin-right: 96px;
  position: relative;

  :before {
    position: absolute;
    content: '';
    left: -70px;
    top: 50%;
    height: 1px;
    width: 50px;
    background: ${({ theme }) => theme.colors.borders.delicate};
  }

  ${forScreenAtMost1024pxWide(css`
    margin-left: 45px;
    margin-right: 56px;

    :before {
      left: -30px;
      width: 20px;
    }
  `)}

  ${forScreenAtMost680pxWide(css`
    margin-left: 0px;
    margin-right: auto;

    :before {
      left: -50px;
      width: 40px;
    }
  `)}
`;

const DataSourceIconWrapper = styled.div`
  position: relative;
  margin-right: 131px;

  :before {
    content: '';
    width: 409px;
    height: 409px;
    background-image: url('${orbits}');
    background-size: 100%;
    background-position: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    position: relative;
    z-index: ${({ theme }) => theme.layers.base + 1};
  }

  ${forScreenAtMost1024pxWide(css`
    margin-right: 41px;
  `)}

  ${forScreenAtMost680pxWide(css`
    margin-right: auto;

    transform: translateX(-30px);
  `)}
`;

const DataSourceContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: ${({ theme }) => theme.layers.base + 1};

  ${forScreenAtMost680pxWide(css`
    margin-top: 60px;
  `)}
`;

const StyledHeader = styled(Header).attrs({ $color: 'secondary' })`
  width: 100%;
  margin: 0 0 28px 0;
`;

const TwoColumnText = styled(TextMedium).attrs({
  $color: 'delicateStronger',
})`
  columns: 2 auto;
  column-gap: 58px;
  padding-left: 30px;

  strong {
    color: ${({ theme }) => theme.colors.texts.tertiary};
  }

  ${forScreenAtMost1200pxWide(css`
    columns: 2 228px;
  `)}
`;

export default DataSourcesSection;
